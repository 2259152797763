import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { User } from "app/core/user/user.types";
import { environment } from "../../../environments/environment";
import { Apollo, gql } from "apollo-angular";
import {
  ResendVerificationCodeGQL,
  UpdateMyAccountDetailsGQL,
  UpdateUserAvatarGQL,
  VerifyPhoneNumberGQL,
} from "generated/graphql";
import { BackendError } from "app/backenderror";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private apollo: Apollo,
    private updateAvatarMutation: UpdateUserAvatarGQL,
    private updateMyAccountMutation: UpdateMyAccountDetailsGQL,
    private verifyPhoneNumberMutation: VerifyPhoneNumberGQL,
    private resendVerificationCodeMutation: ResendVerificationCodeGQL
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user() {
    return this._user.value;
  }

  get user$(): Observable<User> {
    return this.get(false);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(forcefetch = true): Observable<User> {
    if (!forcefetch && this._user.value) return of(this._user.value);
    return this.apollo
      .query({
        // TODO: move this query into a file for codegen
        query: gql(`
              query {
                Me {
                  ...on AuthenticatedUser {
                    account {
                      accountName
                      accountType
                      business {
                         name
                         identifier
                      }
                    }
                    accountId
                    email
                    superUser
                    legalName {
                      firstName
                      lastName
                    }
                    setup {
                      contactNumberVerified
                    }
                    contactNumber {
                      _id
                      countryCode
                      phoneNumber
                    }
                    avatarURL
                  }
                }
              }    
            `),
        fetchPolicy: "no-cache",
      })
      .pipe(
        map((response) => {
          return response.data["Me"];
        }),
        tap((user) => {
          this._user.next(user);
        })
      );
  }

  /**
   * Custom Methods
   */

  async verifyContactNumber(contactNumberId: string, verificationCode: string) {
    const result = await this.verifyPhoneNumberMutation
      .mutate({
        contactNumberId,
        verificationCode,
      })
      .toPromise();

    try {
      if (result.data.VerifyPhoneNumber.__typename === "VerifyPhoneNumberSuccess") {
        return result.data.VerifyPhoneNumber;
      } else {
        console.error(result.data.VerifyPhoneNumber);
        throw new Error("Unable to verify phone number");
      }
    } finally {
      await this.get(true).toPromise(); // reload user in memory
    }
  }

  async resendVerificationCode(contactNumberId: string) {
    const response = await this.resendVerificationCodeMutation
      .mutate({
        contactNumberId,
      })
      .toPromise();
    return response.data.ResendVerificationCode;
  }

  async updateAvatar(image_token: string) {
    const query = await this.updateAvatarMutation
      .mutate({
        token: image_token,
      })
      .toPromise();

    if (query.data.UpdateAvatar.__typename === "GenericError") {
      throw new Error("Unable to set avatar, GenericError");
    }

    if (query.data.UpdateAvatar.__typename === "UpdateAvatarSuccess") {
      await this.get().toPromise(); // reload cached user
      return query.data.UpdateAvatar.avatarURL;
    }

    throw new Error("Unexpected state");
  }

  async updateMyAccount(abn?: string, businessName?: string, accountName?: string) {
    const query = await this.updateMyAccountMutation
      .mutate({
        abn,
        businessName,
        accountName,
      })
      .toPromise();

    if (query.errors?.length) {
      console.error("Unable to update account details", query.errors);
      throw new Error("Unable to update account details");
    }

    await this.get().toPromise(); // reload cached user
  }
}
