export class BackendError extends Error {
  errorCode: string;
  errorGqlType?: string;

  constructor(error: { errorCode: string; errorMessage: string; __typename?: string }) {
    super(error.errorMessage);
    this.errorCode = error.errorCode;
    this.errorGqlType = error.__typename;
    Object.setPrototypeOf(this, BackendError.prototype);
  }
}
