import { gql } from "apollo-angular";
import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AboutPage = {
  __typename?: "AboutPage";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type Account = {
  __typename?: "Account";
  _id: Scalars["String"];
  accountName: Scalars["String"];
  accountType: AccountType;
  avatarUrl: Scalars["String"];
  billing?: Maybe<AccountBilling>;
  business?: Maybe<Business>;
  commission?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["String"];
  enabled: Scalars["Boolean"];
  listingCount: Scalars["Float"];
  listings: Array<Listing>;
  primaryUser: User;
  primaryUserId: Scalars["String"];
  stripeAccountId?: Maybe<Scalars["Boolean"]>;
  users: Array<User>;
};

export type AccountListingsArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
};

export type AccountBilling = {
  __typename?: "AccountBilling";
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpc>;
  defaultCpc: Scalars["Float"];
};

export enum AccountType {
  B2c = "b2c",
  C2c = "c2c",
}

export type Attribute = {
  __typename?: "Attribute";
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  categoryIds: Array<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
  selectOptions: Array<Scalars["String"]>;
};

export enum AttributeType {
  Boolean = "boolean",
  InputFloat = "input_float",
  InputInt = "input_int",
  InputString = "input_string",
  SelectMulti = "select_multi",
  SelectSingle = "select_single",
}

export type AttributeValue = {
  __typename?: "AttributeValue";
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInt?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
  valueStringArray?: Maybe<Array<Scalars["String"]>>;
};

export type AttributeValueInput = {
  attributeId: Scalars["String"];
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInt?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
  valueStringArray?: Maybe<Array<Scalars["String"]>>;
};

export type AuthenticatedUser = {
  __typename?: "AuthenticatedUser";
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  account: Account;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  emailVerified?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  legalName: LegalName;
  setup: Setup;
  superUser: Scalars["Boolean"];
  user: VerifyPhoneNumberResult;
};

export type AvailabilityResponse = {
  __typename?: "AvailabilityResponse";
  available: Scalars["Boolean"];
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  unavailableDates: Array<Scalars["String"]>;
};

export type AvailableCategory2 = {
  __typename?: "AvailableCategory2";
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  depth: Scalars["Float"];
  originalPath: Scalars["String"];
  path: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type BookOwnListingError = GqlError & {
  __typename?: "BookOwnListingError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type Booking = {
  __typename?: "Booking";
  _id: Scalars["String"];
  checkoutId: Scalars["String"];
  dailyListingPrices: DailyPrices;
  dateCreated: Scalars["DateTime"];
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listing: Listing;
  listingId: Scalars["String"];
  ownerAccount: Account;
  ownerAccountId: Scalars["String"];
  paymentIntentSecret: Scalars["String"];
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  status: BookingStatus;
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type BookingActionResult = Booking | GenericError;

export type BookingHistoryResult = GenericError | OwnerBookingHistory;

export enum BookingStatus {
  Approved = "approved",
  Cancelled = "cancelled",
  Disputed = "disputed",
  OutForRent = "out_for_rent",
  Paid = "paid",
  PaymentFailed = "payment_failed",
  PendingApproval = "pending_approval",
  Rejected = "rejected",
  Returned = "returned",
}

export type BpExportListingsResponse = {
  __typename?: "BpExportListingsResponse";
  csvFileStr: Scalars["String"];
};

export type BpHomepageCategoryTally = {
  __typename?: "BpHomepageCategoryTally";
  category: Category;
  categoryId: Scalars["String"];
  disabledCount: Scalars["Int"];
  enabledCount: Scalars["Int"];
};

export type BpHomepageResponse = {
  __typename?: "BpHomepageResponse";
  categoryTallies: Array<BpHomepageCategoryTally>;
};

export type BpListListingsResponse = {
  __typename?: "BpListListingsResponse";
  page: Array<Listing>;
  totalItems: Scalars["Int"];
};

export type BpOwnerRefExistsResponse = {
  __typename?: "BpOwnerRefExistsResponse";
  exists: Scalars["Boolean"];
  listingId?: Maybe<Scalars["String"]>;
};

export type BrandResponse = {
  __typename?: "BrandResponse";
  brands: Array<Scalars["String"]>;
};

export type Business = {
  __typename?: "Business";
  identifier: Scalars["String"];
  name: Scalars["String"];
};

export type BusinessListingReport = {
  __typename?: "BusinessListingReport";
  clicks?: Maybe<Scalars["Int"]>;
  dateStr: Scalars["String"];
  impressions?: Maybe<Scalars["Int"]>;
  listing: Listing;
  listingId: Scalars["String"];
};

export type Category = {
  __typename?: "Category";
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  category: Category;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<Category>;
  isPrimary: Scalars["Boolean"];
  originalPath: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type CategoryBreadCrumb = {
  __typename?: "CategoryBreadCrumb";
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  urlSlug: Scalars["String"];
};

export type CategoryCpc = {
  __typename?: "CategoryCpc";
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
  originalPath: Scalars["String"];
};

export type CategoryCpcInput = {
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
};

export type CategoryPage = {
  __typename?: "CategoryPage";
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<CategoryBreadCrumb>;
  urlSlug: Scalars["String"];
};

export type CategoryResult = Category | GenericError;

export type ChatChannel = {
  __typename?: "ChatChannel";
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars["String"]>;
  ctaText: Scalars["String"];
  isOwner: Scalars["Boolean"];
  isSeeker: Scalars["Boolean"];
  listing: Listing;
  listingId: Scalars["String"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
};

export type ChatChannelResult = ChatChannel | GenericError;

export type ChatToken = {
  __typename?: "ChatToken";
  token: Scalars["String"];
};

export type ChatTokenResult = ChatToken | GenericError;

export type CheckAccountExistsType = {
  __typename?: "CheckAccountExistsType";
  exists: Scalars["Boolean"];
  provider?: Maybe<Providers>;
};

export type Checkout = {
  __typename?: "Checkout";
  _id: Scalars["String"];
  dailyListingPrices: Array<Scalars["Float"]>;
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  discount: CheckoutDiscount;
  listing: Listing;
  listingId: Scalars["String"];
  ownerMessage: Scalars["String"];
  /** @deprecated No longer used */
  paymentIntentSecret?: Maybe<Scalars["String"]>;
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  setupIntentSecret: Scalars["String"];
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type CheckoutDiscount = {
  __typename?: "CheckoutDiscount";
  creditCents: Scalars["Float"];
};

export type CompleteCheckoutResult = Booking | ListingUnavailableError;

export enum Condition {
  Fair = "FAIR",
  Good = "GOOD",
  Likenew = "LIKENEW",
}

export type ContactNumber = {
  __typename?: "ContactNumber";
  _id: Scalars["String"];
  countryCode: Scalars["String"];
  nextResendAvailable?: Maybe<Scalars["DateTime"]>;
  phoneNumber: Scalars["String"];
  verificationAttempts: Scalars["Float"];
  verified: Scalars["Boolean"];
};

export type ContactNumberInput = {
  countryCode: Scalars["String"];
  mobileNumber: Scalars["String"];
};

export type ContactPage = {
  __typename?: "ContactPage";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type CreateCheckoutResult = BookOwnListingError | Checkout | ListingNotFoundError | ListingUnavailableError;

export type CreateListingBatchSuccess = {
  __typename?: "CreateListingBatchSuccess";
  batch: Scalars["Boolean"];
  success: Scalars["Boolean"];
};

export type CreateListingResult = CreateListingBatchSuccess | CreateListingSuccess | GenericError;

export type CreateListingSuccess = {
  __typename?: "CreateListingSuccess";
  listing: Listing;
  success: Scalars["Boolean"];
};

export type CreditHistory = {
  __typename?: "CreditHistory";
  createdOn: Scalars["DateTime"];
  creditCents: Scalars["Float"];
  expiryDate?: Maybe<Scalars["DateTime"]>;
  reason: Scalars["String"];
  reference: Scalars["String"];
};

export type DailyPrices = {
  __typename?: "DailyPrices";
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export enum ENotLiveReason {
  AccountNotApproved = "ACCOUNT_NOT_APPROVED",
  AccountStripeTransfers = "ACCOUNT_STRIPE_TRANSFERS",
  Disabled = "DISABLED",
  NotApproved = "NOT_APPROVED",
}

export enum ESuListAccountFilterEnum {
  Disputed = "disputed",
  NeedsEnabling = "needs_enabling",
  NewSignups = "new_signups",
  None = "none",
  Popular = "popular",
}

export type EditListingImageInput = {
  _id: Scalars["String"];
  sortOrder: Scalars["Float"];
};

export type EditListingResult = EditListingSuccess | GenericError;

export type EditListingSuccess = {
  __typename?: "EditListingSuccess";
  listing: Listing;
};

export type ExternalBankAccount = {
  __typename?: "ExternalBankAccount";
  accountHolderName: Scalars["String"];
  bankAccountId: Scalars["String"];
  bankName: Scalars["String"];
  isPrimary: Scalars["Boolean"];
  last4: Scalars["String"];
};

export type ExternalListing = Listing & {
  __typename?: "ExternalListing";
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  externalURL?: Maybe<Scalars["String"]>;
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type GenericError = {
  __typename?: "GenericError";
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type GenericResult = GenericError | GenericSuccessType;

export type GenericSuccessType = {
  __typename?: "GenericSuccessType";
  success: Scalars["Boolean"];
};

export type GetCreditResponse = {
  __typename?: "GetCreditResponse";
  creditBalance: Scalars["Float"];
  creditHistory: Array<CreditHistory>;
};

export type GqlError = {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ImageInput = {
  externalImageURL?: Maybe<Scalars["String"]>;
  imageToken?: Maybe<Scalars["String"]>;
  sortOrder: Scalars["Float"];
};

export type ImageToken = {
  __typename?: "ImageToken";
  token: Scalars["String"];
};

export type InternalListing = Listing & {
  __typename?: "InternalListing";
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  condition: Condition;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  rules: Array<Scalars["String"]>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type LegalName = {
  __typename?: "LegalName";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type Listing = {
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type ListingImage = {
  __typename?: "ListingImage";
  _id: Scalars["String"];
  heroURL?: Maybe<Scalars["String"]>;
  /** Original Image */
  imageURL: Scalars["String"];
  sortOrder: Scalars["Float"];
  thumbnailURL?: Maybe<Scalars["String"]>;
};

export type ListingLocation = {
  __typename?: "ListingLocation";
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type ListingLocationInput = {
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type ListingNotFoundError = GqlError & {
  __typename?: "ListingNotFoundError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingRecommendations = {
  __typename?: "ListingRecommendations";
  heading: Scalars["String"];
  listings: Array<Listing>;
};

export enum ListingSortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export enum ListingSortOrder {
  CreatedOn = "createdOn",
  ModifiedOn = "modifiedOn",
}

export enum ListingType {
  External = "external",
  Internal = "internal",
}

export type ListingUnavailableError = GqlError & {
  __typename?: "ListingUnavailableError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingsInput = {
  loggedInUser?: Maybe<Scalars["Boolean"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type LoginFailure = {
  __typename?: "LoginFailure";
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type LoginResult = LoginFailure | LoginSuccess;

export type LoginSuccess = {
  __typename?: "LoginSuccess";
  access_token: Scalars["String"];
  account: Account;
  refresh_token: Scalars["String"];
  user: AuthenticatedUser;
};

export type MigrationInvalidListingsResponse = {
  __typename?: "MigrationInvalidListingsResponse";
  invalid: Array<MigrationInvalidListingsResponseListing>;
};

export type MigrationInvalidListingsResponseListing = {
  __typename?: "MigrationInvalidListingsResponseListing";
  error_message?: Maybe<Scalars["String"]>;
  listingId: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  AddExternalBankAccount: GenericSuccessType;
  BpDisableListing: Listing;
  BpEnableListing: Listing;
  BpMoveListingCategory: Listing;
  BpMoveListingLocation: Listing;
  CaptureOnboardingInformation: OnboardingResponse;
  ChangeEmailAddress: GenericResult;
  CompleteCheckout: CompleteCheckoutResult;
  CreateChannel: GenericResult;
  CreateCheckout: CreateCheckoutResult;
  CreateExchangeToken: CreateExchangeTokenResponse;
  CreateListing: CreateListingResult;
  CreateSupportRequest: GenericSuccessType;
  DeleteExternalBankAccount: GenericSuccessType;
  ExchangeToken: LoginResult;
  LoginWithPassword: LoginResult;
  Logout: GenericSuccessType;
  MigrationAccountSetBilling: Scalars["Boolean"];
  MigrationInvalidListings: MigrationInvalidListingsResponse;
  MigrationListingLiveStatus: Scalars["Boolean"];
  MigrationUserSuperUser: Scalars["Boolean"];
  OwnerApproveBooking: BookingActionResult;
  OwnerDisputeBooking: BookingActionResult;
  OwnerRejectBooking: BookingActionResult;
  OwnerReleaseBooking: BookingActionResult;
  OwnerReturnBooking: BookingActionResult;
  RefreshJWT: LoginResult;
  Register: RegisterResult;
  RegisterPushNotificationToken: GenericSuccessType;
  RequestPasswordReset: GenericSuccessType;
  RequestPayout: RequestPayoutResult;
  ResendVerificationCode: GenericResult;
  ResetPassword: GenericSuccessType;
  SeekerCancelBooking: BookingActionResult;
  SeekerDisputeBooking: BookingActionResult;
  SetExternalBankAccountAsPrimary: GenericSuccessType;
  SuApproveB2cAccount: Account;
  SuApproveListing: Scalars["Boolean"];
  SuConvertAccountC2c: Scalars["Boolean"];
  SuSwitchActiveAccount: User;
  SuUnApproveListing: Scalars["Boolean"];
  SuUnapproveB2cAccount: Account;
  SuUpdateBilling: Scalars["Boolean"];
  SuVerifyPhoneNumber: Scalars["Boolean"];
  ToggleListing: Scalars["Boolean"];
  UpdateAvatar: UpdateAvatarResult;
  UpdateListing: EditListingResult;
  UpdateMyAccountDetails: Account;
  UpdateUser: AuthenticatedUser;
  UploadIdentityDocuments: OnboardingResponse;
  VerifyEmail: GenericSuccessType;
  VerifyPhoneNumber: VerifyPhoneNumberResult;
};

export type MutationAddExternalBankAccountArgs = {
  bankAccountToken: Scalars["String"];
};

export type MutationBpDisableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpEnableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpMoveListingCategoryArgs = {
  categoryId: Scalars["String"];
  listingId: Scalars["String"];
};

export type MutationBpMoveListingLocationArgs = {
  listingId: Scalars["String"];
  location: ListingLocationInput;
};

export type MutationCaptureOnboardingInformationArgs = {
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  dateOfBirthDay: Scalars["Int"];
  dateOfBirthMonth: Scalars["Int"];
  dateOfBirthYear: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
};

export type MutationChangeEmailAddressArgs = {
  email: Scalars["String"];
};

export type MutationCompleteCheckoutArgs = {
  checkoutId: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type MutationCreateChannelArgs = {
  listingId: Scalars["String"];
};

export type MutationCreateCheckoutArgs = {
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listingId: Scalars["String"];
};

export type MutationCreateExchangeTokenArgs = {
  refreshToken: Scalars["String"];
};

export type MutationCreateListingArgs = {
  accountId?: Maybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: Maybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: Maybe<Condition>;
  description: Scalars["String"];
  externalURL?: Maybe<Scalars["String"]>;
  images: Array<ImageInput>;
  keywords: Array<Scalars["String"]>;
  location?: Maybe<ListingLocationInput>;
  ownerRef?: Maybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationCreateSupportRequestArgs = {
  body: Scalars["String"];
  bookingId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationDeleteExternalBankAccountArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationExchangeTokenArgs = {
  exchangeToken: Scalars["String"];
};

export type MutationLoginWithPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  staySignedIn: Scalars["Boolean"];
};

export type MutationOwnerApproveBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerRejectBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReleaseBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReturnBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationRefreshJwtArgs = {
  refreshToken?: Maybe<Scalars["String"]>;
};

export type MutationRegisterArgs = {
  abn?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  mobileNumber: ContactNumberInput;
  password: Scalars["String"];
  referrerUserId?: Maybe<Scalars["String"]>;
};

export type MutationRegisterPushNotificationTokenArgs = {
  token: Scalars["String"];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"];
};

export type MutationResendVerificationCodeArgs = {
  contactNumberId: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSeekerCancelBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSeekerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSetExternalBankAccountAsPrimaryArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationSuApproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuConvertAccountC2cArgs = {
  accountId: Scalars["String"];
};

export type MutationSuSwitchActiveAccountArgs = {
  accountId: Scalars["String"];
};

export type MutationSuUnApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuUnapproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuUpdateBillingArgs = {
  accountId: Scalars["String"];
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpcInput>;
  defaultCpc: Scalars["Float"];
};

export type MutationSuVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  userId: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type MutationToggleListingArgs = {
  listingId: Scalars["String"];
};

export type MutationUpdateAvatarArgs = {
  imageToken: Scalars["String"];
};

export type MutationUpdateListingArgs = {
  accountId?: Maybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: Maybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: Maybe<Condition>;
  description: Scalars["String"];
  editImages: Array<EditListingImageInput>;
  externalURL?: Maybe<Scalars["String"]>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  location?: Maybe<ListingLocationInput>;
  newImages: Array<ImageInput>;
  ownerRef?: Maybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationUpdateMyAccountDetailsArgs = {
  abn?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
};

export type MutationUpdateUserArgs = {
  aboutMe?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type MutationUploadIdentityDocumentsArgs = {
  back?: Maybe<Scalars["String"]>;
  front: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MyReferralLink = {
  __typename?: "MyReferralLink";
  link: Scalars["String"];
};

export type Navigation = {
  __typename?: "Navigation";
  cacheTime: Scalars["Float"];
  /** Array of category and an active listing count */
  categories: Array<NavigationCategory>;
  /** Array of footer link columns */
  footer: Array<NavigationFooterColumns>;
  /** Array of promo links for the top navigation */
  promoLinks: Array<NavigationLink>;
};

export type Navigation2 = {
  __typename?: "Navigation2";
  cacheTime: Scalars["Float"];
  /** Array of category and an active listing count */
  categories: Array<NavigationTopCategory>;
  /** Array of footer link columns */
  footer: Array<NavigationFooterColumns>;
  /** Array of promo links for the top navigation */
  promoLinks: Array<NavigationLink>;
};

export type NavigationCategory = {
  __typename?: "NavigationCategory";
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  depth: Scalars["Float"];
  parentCategoryId?: Maybe<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type NavigationCategory2 = {
  __typename?: "NavigationCategory2";
  /** Categories 1 level down */
  children: Array<NavigationCategory2>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationFooterColumns = {
  __typename?: "NavigationFooterColumns";
  /** Ordered list of links */
  links: Array<NavigationLink>;
  title: Scalars["String"];
};

export type NavigationLink = {
  __typename?: "NavigationLink";
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationResult = GenericError | Navigation;

export type NavigationResult2 = GenericError | Navigation2;

export type NavigationTopCategory = {
  __typename?: "NavigationTopCategory";
  /** Categories 1 level down */
  children: Array<NavigationCategory2>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  /** Ordered list of popular brands by # of listings */
  popularBrands: Array<NavigationLink>;
  title: Scalars["String"];
};

export type OnboardingErrors = {
  __typename?: "OnboardingErrors";
  code: Scalars["String"];
  reason: Scalars["String"];
  requirement: Scalars["String"];
};

export type OnboardingLink = {
  __typename?: "OnboardingLink";
  url: Scalars["String"];
};

export type OnboardingResponse = {
  __typename?: "OnboardingResponse";
  errors: Array<OnboardingErrors>;
  profile: StripeProfileInfo;
  status: StripeStatus;
};

export type OwnerBookingHistory = {
  __typename?: "OwnerBookingHistory";
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type Page = {
  __typename?: "Page";
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  canonicalUrl: Scalars["String"];
  heading: Scalars["String"];
  metaDescription: Scalars["String"];
  publishedTime: Scalars["Float"];
  robotsNoFollow: Scalars["Boolean"];
  robotsNoIndex: Scalars["Boolean"];
  title: Scalars["String"];
  updatedTime: Scalars["Float"];
  urlSlug: Scalars["String"];
};

export type Pricing = {
  __typename?: "Pricing";
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export type PricingInput = {
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export type PricingResponse = {
  __typename?: "PricingResponse";
  bondPrice: Scalars["Int"];
  dailyPrices: DailyPrices;
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  totalPrice: Scalars["Int"];
};

export enum Providers {
  Apple = "apple",
  Facebook = "facebook",
  Google = "google",
  Password = "password",
}

export type Query = {
  __typename?: "Query";
  AboutPage: AboutPage;
  AvailableCategories: Array<CategoryResult>;
  AvailableCategories2: Array<AvailableCategory2>;
  Booking: Booking;
  BpCategoriesSummary: BpHomepageResponse;
  BpExportCsvListings: BpExportListingsResponse;
  BpGetBookings: Array<Booking>;
  BpListListings: BpListListingsResponse;
  BpOwnerRefExists: BpOwnerRefExistsResponse;
  CalculateBookingPrice: PricingResponse;
  Category: CategoryResult;
  CategoryAttributes: Array<Attribute>;
  CategoryPage?: Maybe<CategoryPage>;
  ChatChannel: ChatChannelResult;
  ChatToken: ChatTokenResult;
  CheckAccountExists: CheckAccountExistsType;
  CheckListingAvailability: AvailabilityResponse;
  CheckOnboardingStatus: OnboardingResponse;
  Checkout?: Maybe<Checkout>;
  CommunityCategories?: Maybe<Array<Category>>;
  ContactPage: ContactPage;
  CreateOnboardingLink: OnboardingLink;
  CreateVerificationSession: VerificationSession;
  FetchBusinessListingReports: Array<BusinessListingReport>;
  GetAccount: Account;
  GetAllExternalBankAccounts: Array<ExternalBankAccount>;
  GetBrandPage: BrandResponse;
  GetBrandSlug?: Maybe<Scalars["String"]>;
  GetCredit: GetCreditResponse;
  GetMyReferralLink: MyReferralLink;
  GetReferralHistory: Array<ReferralHistory>;
  GetStripeBalance: StripeBalance;
  GetStripeTransfersStatus?: Maybe<Scalars["String"]>;
  GetUser: User;
  HomePageRecommendations: Array<ListingRecommendations>;
  Listing?: Maybe<Listing>;
  Listings: Array<Listing>;
  LocalityAutocomplete: Array<ListingLocation>;
  LocalityCsvAutocomplete: ListingLocation;
  Me: AuthenticatedUser;
  Navigation: NavigationResult;
  Navigation2: NavigationResult2;
  OwnerBookingHistory: BookingHistoryResult;
  Page: Page;
  RentalHistory: RentalHistoryResult;
  RequestImageToken: Array<ImageToken>;
  SearchConfig: SearchConfig;
  StorePage?: Maybe<StorePage>;
  SuGetAccount: Account;
  SuListAccounts: SuListAccountsResponse;
  User?: Maybe<User>;
};

export type QueryAvailableCategories2Args = {
  depth: Scalars["Int"];
};

export type QueryBookingArgs = {
  bookingId: Scalars["String"];
};

export type QueryBpCategoriesSummaryArgs = {
  accountId?: Maybe<Scalars["String"]>;
  allAccounts?: Maybe<Scalars["Boolean"]>;
};

export type QueryBpExportCsvListingsArgs = {
  accountId?: Maybe<Scalars["String"]>;
  all_accounts?: Maybe<Scalars["Boolean"]>;
  approved?: Maybe<Scalars["Boolean"]>;
  categoryId?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  listingType?: Maybe<ListingType>;
  live?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<ListingLocationInput>;
  notLiveReason?: Maybe<ENotLiveReason>;
  search_term?: Maybe<Scalars["String"]>;
};

export type QueryBpGetBookingsArgs = {
  statuses?: Maybe<Array<BookingStatus>>;
};

export type QueryBpListListingsArgs = {
  accountId?: Maybe<Scalars["String"]>;
  all_accounts?: Maybe<Scalars["Boolean"]>;
  approved?: Maybe<Scalars["Boolean"]>;
  categoryId?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  limit: Scalars["Int"];
  listingType?: Maybe<ListingType>;
  live?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<ListingLocationInput>;
  notLiveReason?: Maybe<ENotLiveReason>;
  offset: Scalars["Int"];
  search_term?: Maybe<Scalars["String"]>;
  sort_direction?: Maybe<ListingSortDirection>;
  sort_order?: Maybe<ListingSortOrder>;
};

export type QueryBpOwnerRefExistsArgs = {
  accountId: Scalars["String"];
  ignoreListingId?: Maybe<Scalars["String"]>;
  ownerRef: Scalars["String"];
};

export type QueryCalculateBookingPriceArgs = {
  endDate: Scalars["String"];
  listingId: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryCategoryArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryAttributesArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryPageArgs = {
  categoryId?: Maybe<Scalars["String"]>;
  urlSlug?: Maybe<Scalars["String"]>;
};

export type QueryChatChannelArgs = {
  channelId: Scalars["String"];
};

export type QueryCheckAccountExistsArgs = {
  email: Scalars["String"];
};

export type QueryCheckListingAvailabilityArgs = {
  endDate: Scalars["DateTime"];
  listingId: Scalars["String"];
  startDate: Scalars["DateTime"];
};

export type QueryCheckoutArgs = {
  _id: Scalars["String"];
};

export type QueryCommunityCategoriesArgs = {
  postcodes: Array<Scalars["String"]>;
};

export type QueryCreateOnboardingLinkArgs = {
  refreshURL: Scalars["String"];
  returnURL: Scalars["String"];
};

export type QueryCreateVerificationSessionArgs = {
  checkoutSessionId?: Maybe<Scalars["String"]>;
};

export type QueryFetchBusinessListingReportsArgs = {
  accountId?: Maybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type QueryGetAccountArgs = {
  accountId: Scalars["String"];
};

export type QueryGetBrandPageArgs = {
  slug: Scalars["String"];
};

export type QueryGetBrandSlugArgs = {
  brandName: Scalars["String"];
};

export type QueryGetUserArgs = {
  _id: Scalars["String"];
};

export type QueryListingArgs = {
  listingId: Scalars["String"];
};

export type QueryListingsArgs = {
  filter?: Maybe<ListingsInput>;
  limit?: Maybe<Scalars["Float"]>;
  skip?: Maybe<Scalars["Boolean"]>;
};

export type QueryLocalityAutocompleteArgs = {
  localityPartial: Scalars["String"];
};

export type QueryLocalityCsvAutocompleteArgs = {
  postcode: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
};

export type QueryPageArgs = {
  urlSlug: Scalars["String"];
};

export type QueryRequestImageTokenArgs = {
  qty?: Maybe<Scalars["Int"]>;
};

export type QueryStorePageArgs = {
  accountId: Scalars["String"];
};

export type QuerySuGetAccountArgs = {
  _id: Scalars["String"];
};

export type QuerySuListAccountsArgs = {
  filter: ESuListAccountFilterEnum;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search_term?: Maybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  userId: Scalars["String"];
};

export type ReferralHistory = {
  __typename?: "ReferralHistory";
  createdAt: Scalars["DateTime"];
  creditAmountCents: Scalars["Float"];
  referredUserName: Scalars["String"];
  status: ReferralStatus;
};

export enum ReferralStatus {
  Credited = "credited",
  Expired = "expired",
  Pending = "pending",
}

export type RegisterAccountExistsError = GqlError & {
  __typename?: "RegisterAccountExistsError";
  code: Scalars["String"];
  message: Scalars["String"];
};

export type RegisterInvalidInputError = GqlError & {
  __typename?: "RegisterInvalidInputError";
  abnError?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  companyNameError?: Maybe<Scalars["String"]>;
  emailError?: Maybe<Scalars["String"]>;
  firstNameError?: Maybe<Scalars["String"]>;
  lastNameError?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  mobileNumberError?: Maybe<Scalars["String"]>;
  passwordError?: Maybe<Scalars["String"]>;
};

export type RegisterResult = RegisterAccountExistsError | RegisterInvalidInputError | RegisterSuccess;

export type RegisterSuccess = {
  __typename?: "RegisterSuccess";
  contactNumberId: Scalars["String"];
  user: AuthenticatedUser;
};

export type RentalHistory = {
  __typename?: "RentalHistory";
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type RentalHistoryResult = GenericError | RentalHistory;

export type RequestPayoutResult = GenericError | RequestPayoutSuccess;

export type RequestPayoutSuccess = {
  __typename?: "RequestPayoutSuccess";
  arrival_date: Scalars["Float"];
};

export type SearchConfig = {
  __typename?: "SearchConfig";
  account: Scalars["String"];
  collection: Scalars["String"];
};

export type Setup = {
  __typename?: "Setup";
  contactNumberVerified: Scalars["Boolean"];
};

export type StorePage = {
  __typename?: "StorePage";
  accountId: Scalars["String"];
  accountName: Scalars["String"];
};

export type StripeBalance = {
  __typename?: "StripeBalance";
  balance: Scalars["Float"];
  pending_balance: Scalars["Float"];
};

export type StripeProfileInfo = {
  __typename?: "StripeProfileInfo";
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  dateOfBirthDay?: Maybe<Scalars["Int"]>;
  dateOfBirthMonth?: Maybe<Scalars["Int"]>;
  dateOfBirthYear?: Maybe<Scalars["Int"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export enum StripeStatus {
  Pending = "pending",
  Unverified = "unverified",
  Verified = "verified",
}

export type SuListAccountsResponse = {
  __typename?: "SuListAccountsResponse";
  page: Array<Account>;
  totalItems: Scalars["Float"];
};

export type UnavailableDatesResponse = {
  __typename?: "UnavailableDatesResponse";
  unavailableDates: Array<Scalars["String"]>;
  unavailableDaysOfWeek: Array<Scalars["Int"]>;
};

export type UpdateAvatarResult = GenericError | UpdateAvatarSuccess;

export type UpdateAvatarSuccess = {
  __typename?: "UpdateAvatarSuccess";
  avatarURL: Scalars["String"];
};

export type User = {
  __typename?: "User";
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  legalName: LegalName;
  superUser: Scalars["Boolean"];
  user: VerifyPhoneNumberResult;
};

export type VerificationSession = {
  __typename?: "VerificationSession";
  clientSecret: Scalars["String"];
  url: Scalars["String"];
};

export type VerifyPhoneNumberResult = GenericError | VerifyPhoneNumberSuccess;

export type VerifyPhoneNumberSuccess = {
  __typename?: "VerifyPhoneNumberSuccess";
  success: Scalars["Boolean"];
  user: User;
  userId: Scalars["String"];
};

export type CreateExchangeTokenResponse = {
  __typename?: "createExchangeTokenResponse";
  exchangeToken: Scalars["String"];
};

export type BpMoveListingCategoryMutationVariables = Exact<{
  categoryId: Scalars["String"];
  listingId: Scalars["String"];
}>;

export type BpMoveListingCategoryMutation = {
  __typename?: "Mutation";
  BpMoveListingCategory:
    | { __typename?: "ExternalListing"; listingId: string }
    | { __typename?: "InternalListing"; listingId: string };
};

export type BpMoveListingLocationMutationVariables = Exact<{
  location: ListingLocationInput;
  listingId: Scalars["String"];
}>;

export type BpMoveListingLocationMutation = {
  __typename?: "Mutation";
  BpMoveListingLocation:
    | { __typename?: "ExternalListing"; listingId: string }
    | { __typename?: "InternalListing"; listingId: string };
};

export type CreateListingSingleMutationVariables = Exact<{
  accountId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  brand?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  price: PricingInput;
  bondPrice: Scalars["Float"];
  images: Array<ImageInput> | ImageInput;
  externalURL?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  ownerRef?: Maybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput> | AttributeValueInput;
  location?: Maybe<ListingLocationInput>;
  keywords: Array<Scalars["String"]> | Scalars["String"];
  condition?: Maybe<Condition>;
  rules?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CreateListingSingleMutation = {
  __typename?: "Mutation";
  CreateListing:
    | { __typename?: "CreateListingBatchSuccess" }
    | {
        __typename?: "CreateListingSuccess";
        listing:
          | { __typename?: "ExternalListing"; listingId: string }
          | { __typename?: "InternalListing"; listingId: string };
      }
    | { __typename?: "GenericError"; errorCode: string; errorMessage: string };
};

export type LoginWithPasswordMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginWithPasswordMutation = {
  __typename?: "Mutation";
  LoginWithPassword:
    | { __typename?: "LoginFailure"; errorCode: string; errorMessage: string }
    | {
        __typename?: "LoginSuccess";
        access_token: string;
        user: {
          __typename?: "AuthenticatedUser";
          _id: string;
          accountId: string;
          superUser: boolean;
          email: string;
          avatarURL?: Maybe<string>;
          legalName: { __typename?: "LegalName"; firstName: string; lastName: string };
          setup: { __typename?: "Setup"; contactNumberVerified: boolean };
          contactNumber: Array<{
            __typename?: "ContactNumber";
            _id: string;
            countryCode: string;
            phoneNumber: string;
            verified: boolean;
          }>;
          account: {
            __typename?: "Account";
            accountType: AccountType;
            accountName: string;
            business?: Maybe<{ __typename?: "Business"; name: string; identifier: string }>;
          };
        };
      };
};

export type RefreshJwtMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshJwtMutation = {
  __typename?: "Mutation";
  RefreshJWT:
    | { __typename?: "LoginFailure"; errorCode: string; errorMessage: string }
    | {
        __typename?: "LoginSuccess";
        access_token: string;
        user: {
          __typename?: "AuthenticatedUser";
          _id: string;
          accountId: string;
          superUser: boolean;
          email: string;
          avatarURL?: Maybe<string>;
          legalName: { __typename?: "LegalName"; firstName: string; lastName: string };
          setup: { __typename?: "Setup"; contactNumberVerified: boolean };
          contactNumber: Array<{
            __typename?: "ContactNumber";
            _id: string;
            countryCode: string;
            phoneNumber: string;
            verified: boolean;
          }>;
          account: {
            __typename?: "Account";
            accountName: string;
            accountType: AccountType;
            business?: Maybe<{ __typename?: "Business"; name: string; identifier: string }>;
          };
        };
      };
};

export type ResendVerificationCodeMutationVariables = Exact<{
  contactNumberId: Scalars["String"];
}>;

export type ResendVerificationCodeMutation = {
  __typename?: "Mutation";
  ResendVerificationCode:
    | { __typename?: "GenericError"; errorCode: string; errorMessage: string }
    | { __typename?: "GenericSuccessType"; success: boolean };
};

export type ReturnBookingMutationVariables = Exact<{
  bookingId: Scalars["String"];
}>;

export type ReturnBookingMutation = {
  __typename?: "Mutation";
  OwnerReturnBooking: { __typename?: "Booking"; _id: string } | { __typename?: "GenericError" };
};

export type SuUpdateBillingMutationVariables = Exact<{
  categoryCpc: Array<CategoryCpcInput> | CategoryCpcInput;
  defaultCpc: Scalars["Float"];
  budgetMonthlyCents: Scalars["Float"];
  accountId: Scalars["String"];
}>;

export type SuUpdateBillingMutation = { __typename?: "Mutation"; SuUpdateBilling: boolean };

export type SuVerifyPhoneNumberMutationVariables = Exact<{
  verified: Scalars["Boolean"];
  contactNumberId: Scalars["String"];
  userId: Scalars["String"];
}>;

export type SuVerifyPhoneNumberMutation = { __typename?: "Mutation"; SuVerifyPhoneNumber: boolean };

export type UpdateListingMutationVariables = Exact<{
  listingId: Scalars["String"];
  accountId: Scalars["String"];
  title: Scalars["String"];
  brand?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  price: PricingInput;
  bondPrice: Scalars["Float"];
  externalURL?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  ownerRef?: Maybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput> | AttributeValueInput;
  location?: Maybe<ListingLocationInput>;
  keywords: Array<Scalars["String"]> | Scalars["String"];
  newImages: Array<ImageInput> | ImageInput;
  editImages: Array<EditListingImageInput> | EditListingImageInput;
  condition?: Maybe<Condition>;
  rules?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type UpdateListingMutation = {
  __typename?: "Mutation";
  UpdateListing:
    | {
        __typename?: "EditListingSuccess";
        listing:
          | { __typename?: "ExternalListing"; listingId: string }
          | { __typename?: "InternalListing"; listingId: string };
      }
    | { __typename?: "GenericError"; errorCode: string; errorMessage: string };
};

export type CreateListingMutationVariables = Exact<{
  accountId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  brand?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  price: Scalars["Float"];
  bondPrice: Scalars["Float"];
  imageURL: Scalars["String"];
  externalURL?: Maybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  ownerRef: Scalars["String"];
  attributes: Array<AttributeValueInput> | AttributeValueInput;
  location?: Maybe<ListingLocationInput>;
  keywords: Array<Scalars["String"]> | Scalars["String"];
}>;

export type CreateListingMutation = {
  __typename?: "Mutation";
  CreateListing:
    | { __typename?: "CreateListingBatchSuccess" }
    | {
        __typename?: "CreateListingSuccess";
        listing:
          | { __typename?: "ExternalListing"; listingId: string }
          | { __typename?: "InternalListing"; listingId: string };
      }
    | { __typename?: "GenericError"; errorCode: string; errorMessage: string };
};

export type DisableListingMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type DisableListingMutation = {
  __typename?: "Mutation";
  BpDisableListing:
    | { __typename?: "ExternalListing"; listingId: string; enabled: boolean }
    | { __typename?: "InternalListing"; listingId: string; enabled: boolean };
};

export type EnableListingMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type EnableListingMutation = {
  __typename?: "Mutation";
  BpEnableListing:
    | { __typename?: "ExternalListing"; listingId: string; enabled: boolean }
    | { __typename?: "InternalListing"; listingId: string; enabled: boolean };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ForgotPasswordMutation = {
  __typename?: "Mutation";
  RequestPasswordReset: { __typename?: "GenericSuccessType"; success: boolean };
};

export type RegisterMutationVariables = Exact<{
  mobileNumber: ContactNumberInput;
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
  companyName: Scalars["String"];
  abn: Scalars["String"];
}>;

export type RegisterMutation = {
  __typename?: "Mutation";
  Register:
    | { __typename?: "RegisterAccountExistsError" }
    | {
        __typename?: "RegisterInvalidInputError";
        emailError?: Maybe<string>;
        code: string;
        message: string;
        passwordError?: Maybe<string>;
        firstNameError?: Maybe<string>;
        lastNameError?: Maybe<string>;
        mobileNumberError?: Maybe<string>;
        companyNameError?: Maybe<string>;
        abnError?: Maybe<string>;
      }
    | { __typename?: "RegisterSuccess"; user: { __typename?: "AuthenticatedUser"; _id: string } };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  ResetPassword: { __typename?: "GenericSuccessType"; success: boolean };
};

export type SuApproveB2cAccountMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SuApproveB2cAccountMutation = {
  __typename?: "Mutation";
  SuApproveB2cAccount: { __typename?: "Account"; _id: string };
};

export type SuApproveListingMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type SuApproveListingMutation = { __typename?: "Mutation"; SuApproveListing: boolean };

export type SuUnapproveB2cAccountMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SuUnapproveB2cAccountMutation = {
  __typename?: "Mutation";
  SuUnapproveB2cAccount: { __typename?: "Account"; _id: string };
};

export type SuUnApproveListingMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type SuUnApproveListingMutation = { __typename?: "Mutation"; SuUnApproveListing: boolean };

export type UpdateMyAccountDetailsMutationVariables = Exact<{
  abn?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
}>;

export type UpdateMyAccountDetailsMutation = {
  __typename?: "Mutation";
  UpdateMyAccountDetails: { __typename?: "Account"; accountName: string };
};

export type UpdateUserAvatarMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type UpdateUserAvatarMutation = {
  __typename?: "Mutation";
  UpdateAvatar: { __typename?: "GenericError" } | { __typename?: "UpdateAvatarSuccess"; avatarURL: string };
};

export type VerifyPhoneNumberMutationVariables = Exact<{
  verificationCode: Scalars["String"];
  contactNumberId: Scalars["String"];
}>;

export type VerifyPhoneNumberMutation = {
  __typename?: "Mutation";
  VerifyPhoneNumber: { __typename?: "GenericError" } | { __typename?: "VerifyPhoneNumberSuccess"; success: boolean };
};

export type AvailableCategories2QueryVariables = Exact<{
  depth: Scalars["Int"];
}>;

export type AvailableCategories2Query = {
  __typename?: "Query";
  AvailableCategories2: Array<{
    __typename?: "AvailableCategory2";
    categoryId: string;
    categoryName: string;
    urlSlug: string;
    originalPath: string;
    path: string;
    pathMap: Array<string>;
    depth: number;
  }>;
};

export type BpCategoriesSummaryQueryVariables = Exact<{
  accountId?: Maybe<Scalars["String"]>;
  allAccounts?: Maybe<Scalars["Boolean"]>;
}>;

export type BpCategoriesSummaryQuery = {
  __typename?: "Query";
  BpCategoriesSummary: {
    __typename?: "BpHomepageResponse";
    categoryTallies: Array<{
      __typename?: "BpHomepageCategoryTally";
      categoryId: string;
      disabledCount: number;
      enabledCount: number;
      category: { __typename?: "Category"; categoryName: string };
    }>;
  };
};

export type GetCategoryQueryVariables = Exact<{
  categoryId: Scalars["String"];
}>;

export type GetCategoryQuery = {
  __typename?: "Query";
  Category:
    | {
        __typename?: "Category";
        category: {
          __typename?: "Category";
          categoryId: string;
          categoryName: string;
          pathMap: Array<string>;
          originalPath: string;
        };
      }
    | { __typename?: "GenericError" };
};

export type CategoryAttributesQueryVariables = Exact<{
  categoryId: Scalars["String"];
}>;

export type CategoryAttributesQuery = {
  __typename?: "Query";
  CategoryAttributes: Array<{
    __typename?: "Attribute";
    attributeId: string;
    attributeType: AttributeType;
    selectOptions: Array<string>;
    attributeName: string;
    categoryIds: Array<string>;
    required?: Maybe<boolean>;
  }>;
};

export type EditExternalListingGetListingQueryVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type EditExternalListingGetListingQuery = {
  __typename?: "Query";
  Listing?: Maybe<
    | {
        __typename?: "ExternalListing";
        accountId: string;
        listingId: string;
        categoryId: string;
        ownerUserId: string;
        ownerRef?: Maybe<string>;
        title: string;
        description: string;
        keywords: Array<string>;
        bondPrice: number;
        externalURL?: Maybe<string>;
        brand?: Maybe<string>;
        pricing: {
          __typename?: "Pricing";
          monday?: Maybe<number>;
          tuesday?: Maybe<number>;
          wednesday?: Maybe<number>;
          thursday?: Maybe<number>;
          friday?: Maybe<number>;
          saturday?: Maybe<number>;
          sunday?: Maybe<number>;
        };
        images: Array<{
          __typename?: "ListingImage";
          _id: string;
          imageURL: string;
          thumbnailURL?: Maybe<string>;
          heroURL?: Maybe<string>;
          sortOrder: number;
        }>;
        location: {
          __typename?: "ListingLocation";
          postcode?: Maybe<string>;
          state?: Maybe<string>;
          locality?: Maybe<string>;
          latitude?: Maybe<number>;
          longitude?: Maybe<number>;
          national: boolean;
        };
        attributes: Array<{
          __typename?: "AttributeValue";
          attributeId: string;
          attributeType: AttributeType;
          attributeName: string;
          valueInt?: Maybe<number>;
          valueFloat?: Maybe<number>;
          valueString?: Maybe<string>;
          valueStringArray?: Maybe<Array<string>>;
          valueBoolean?: Maybe<boolean>;
        }>;
      }
    | {
        __typename?: "InternalListing";
        listingId: string;
        categoryId: string;
        accountId: string;
        ownerUserId: string;
        ownerRef?: Maybe<string>;
        title: string;
        description: string;
        keywords: Array<string>;
        bondPrice: number;
        brand?: Maybe<string>;
        rules: Array<string>;
        condition: Condition;
        pricing: {
          __typename?: "Pricing";
          monday?: Maybe<number>;
          tuesday?: Maybe<number>;
          wednesday?: Maybe<number>;
          thursday?: Maybe<number>;
          friday?: Maybe<number>;
          saturday?: Maybe<number>;
          sunday?: Maybe<number>;
        };
        images: Array<{
          __typename?: "ListingImage";
          _id: string;
          imageURL: string;
          thumbnailURL?: Maybe<string>;
          heroURL?: Maybe<string>;
          sortOrder: number;
        }>;
        location: {
          __typename?: "ListingLocation";
          postcode?: Maybe<string>;
          state?: Maybe<string>;
          locality?: Maybe<string>;
          latitude?: Maybe<number>;
          longitude?: Maybe<number>;
          national: boolean;
        };
        attributes: Array<{
          __typename?: "AttributeValue";
          attributeId: string;
          attributeType: AttributeType;
          attributeName: string;
          valueInt?: Maybe<number>;
          valueFloat?: Maybe<number>;
          valueString?: Maybe<string>;
          valueStringArray?: Maybe<Array<string>>;
          valueBoolean?: Maybe<boolean>;
        }>;
      }
  >;
};

export type BpExportCsvListingsQueryVariables = Exact<{
  enabled?: Maybe<Scalars["Boolean"]>;
  categoryId?: Maybe<Scalars["String"]>;
  search_term?: Maybe<Scalars["String"]>;
  accountId?: Maybe<Scalars["String"]>;
  approved?: Maybe<Scalars["Boolean"]>;
  all_accounts?: Maybe<Scalars["Boolean"]>;
  listingType?: Maybe<ListingType>;
  location?: Maybe<ListingLocationInput>;
  live?: Maybe<Scalars["Boolean"]>;
  notLiveReason?: Maybe<ENotLiveReason>;
}>;

export type BpExportCsvListingsQuery = {
  __typename?: "Query";
  BpExportCsvListings: { __typename?: "BpExportListingsResponse"; csvFileStr: string };
};

export type FetchBusinessListingReportsQueryVariables = Exact<{
  startDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
  accountId?: Maybe<Scalars["String"]>;
}>;

export type FetchBusinessListingReportsQuery = {
  __typename?: "Query";
  FetchBusinessListingReports: Array<{
    __typename: "BusinessListingReport";
    clicks?: Maybe<number>;
    impressions?: Maybe<number>;
    listingId: string;
    dateStr: string;
    listing: { __typename?: "ExternalListing"; title: string } | { __typename?: "InternalListing"; title: string };
  }>;
};

export type GetAvailableCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableCategoriesQuery = {
  __typename?: "Query";
  AvailableCategories: Array<
    | {
        __typename?: "Category";
        category: { __typename?: "Category"; categoryId: string; categoryName: string; urlSlug: string };
      }
    | { __typename?: "GenericError" }
  >;
};

export type GetBookingsQueryVariables = Exact<{
  statuses?: Maybe<Array<BookingStatus> | BookingStatus>;
}>;

export type GetBookingsQuery = {
  __typename?: "Query";
  BpGetBookings: Array<{
    __typename?: "Booking";
    _id: string;
    listingId: string;
    dateStart: any;
    dateEnd: any;
    dateCreated: any;
    status: BookingStatus;
    totalOfferPrice: number;
    refundableBondPrice: number;
    listing:
      | {
          __typename?: "ExternalListing";
          listingId: string;
          title: string;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: Maybe<string> }>;
        }
      | {
          __typename?: "InternalListing";
          listingId: string;
          title: string;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: Maybe<string> }>;
        };
    seekerUser: {
      __typename?: "User";
      _id: string;
      email: string;
      avatarURL?: Maybe<string>;
      legalName: { __typename?: "LegalName"; firstName: string; lastName: string };
      contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string; countryCode: string }>;
    };
    ownerAccount: {
      __typename?: "Account";
      _id: string;
      accountName: string;
      avatarUrl: string;
      primaryUser: {
        __typename?: "User";
        email: string;
        contactNumber: Array<{ __typename?: "ContactNumber"; phoneNumber: string; countryCode: string }>;
      };
    };
  }>;
};

export type AvailableCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableCategoriesQuery = {
  __typename?: "Query";
  AvailableCategories: Array<
    | { __typename: "Category"; category: { __typename?: "Category"; categoryId: string; categoryName: string } }
    | { __typename: "GenericError" }
  >;
};

export type GetMyListingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyListingsQuery = {
  __typename?: "Query";
  Listings: Array<
    | {
        __typename?: "ExternalListing";
        title: string;
        description: string;
        ownerRef?: Maybe<string>;
        enabled: boolean;
        category: { __typename?: "Category"; categoryName: string };
        pricing: {
          __typename?: "Pricing";
          monday?: Maybe<number>;
          tuesday?: Maybe<number>;
          wednesday?: Maybe<number>;
          thursday?: Maybe<number>;
          friday?: Maybe<number>;
        };
        images: Array<{
          __typename?: "ListingImage";
          sortOrder: number;
          thumbnailURL?: Maybe<string>;
          heroURL?: Maybe<string>;
        }>;
      }
    | {
        __typename?: "InternalListing";
        title: string;
        description: string;
        ownerRef?: Maybe<string>;
        enabled: boolean;
        category: { __typename?: "Category"; categoryName: string };
        pricing: {
          __typename?: "Pricing";
          monday?: Maybe<number>;
          tuesday?: Maybe<number>;
          wednesday?: Maybe<number>;
          thursday?: Maybe<number>;
          friday?: Maybe<number>;
        };
        images: Array<{
          __typename?: "ListingImage";
          sortOrder: number;
          thumbnailURL?: Maybe<string>;
          heroURL?: Maybe<string>;
        }>;
      }
  >;
};

export type BpListListingsQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search_term?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  accountId?: Maybe<Scalars["String"]>;
  approved?: Maybe<Scalars["Boolean"]>;
  all_accounts?: Maybe<Scalars["Boolean"]>;
  listingType?: Maybe<ListingType>;
  sort_direction?: Maybe<ListingSortDirection>;
  sort_order?: Maybe<ListingSortOrder>;
  location?: Maybe<ListingLocationInput>;
  live?: Maybe<Scalars["Boolean"]>;
  notLiveReason?: Maybe<ENotLiveReason>;
}>;

export type BpListListingsQuery = {
  __typename?: "Query";
  BpListListings: {
    __typename?: "BpListListingsResponse";
    totalItems: number;
    page: Array<
      | {
          __typename?: "ExternalListing";
          _id: string;
          listingId: string;
          accountId: string;
          title: string;
          live: boolean;
          notLiveReason?: Maybe<ENotLiveReason>;
          description: string;
          ownerRef?: Maybe<string>;
          listingType: ListingType;
          enabled: boolean;
          approved: boolean;
          externalURL?: Maybe<string>;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: Maybe<string> }>;
          category: { __typename?: "Category"; originalPath: string };
          location: {
            __typename?: "ListingLocation";
            national: boolean;
            postcode?: Maybe<string>;
            state?: Maybe<string>;
            locality?: Maybe<string>;
          };
        }
      | {
          __typename?: "InternalListing";
          _id: string;
          listingId: string;
          accountId: string;
          title: string;
          live: boolean;
          notLiveReason?: Maybe<ENotLiveReason>;
          description: string;
          ownerRef?: Maybe<string>;
          listingType: ListingType;
          enabled: boolean;
          approved: boolean;
          images: Array<{ __typename?: "ListingImage"; thumbnailURL?: Maybe<string> }>;
          category: { __typename?: "Category"; originalPath: string };
          location: {
            __typename?: "ListingLocation";
            national: boolean;
            postcode?: Maybe<string>;
            state?: Maybe<string>;
            locality?: Maybe<string>;
          };
        }
    >;
  };
};

export type LocalityAutocompleteQueryVariables = Exact<{
  term: Scalars["String"];
}>;

export type LocalityAutocompleteQuery = {
  __typename?: "Query";
  LocalityAutocomplete: Array<{
    __typename?: "ListingLocation";
    locality?: Maybe<string>;
    postcode?: Maybe<string>;
    state?: Maybe<string>;
    latitude?: Maybe<number>;
    longitude?: Maybe<number>;
  }>;
};

export type LocationCsvAutocompleteQueryVariables = Exact<{
  postcode: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
}>;

export type LocationCsvAutocompleteQuery = {
  __typename?: "Query";
  LocalityCsvAutocomplete: {
    __typename?: "ListingLocation";
    postcode?: Maybe<string>;
    state?: Maybe<string>;
    locality?: Maybe<string>;
    latitude?: Maybe<number>;
    longitude?: Maybe<number>;
    national: boolean;
  };
};

export type OwnerRefExistsQueryVariables = Exact<{
  ownerRef: Scalars["String"];
  accountId: Scalars["String"];
  ignoreListingId?: Maybe<Scalars["String"]>;
}>;

export type OwnerRefExistsQuery = {
  __typename?: "Query";
  BpOwnerRefExists: { __typename?: "BpOwnerRefExistsResponse"; exists: boolean; listingId?: Maybe<string> };
};

export type RequestImageTokenQueryVariables = Exact<{
  qty: Scalars["Int"];
}>;

export type RequestImageTokenQuery = {
  __typename?: "Query";
  RequestImageToken: Array<{ __typename?: "ImageToken"; token: string }>;
};

export type SuGetAccountQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type SuGetAccountQuery = {
  __typename?: "Query";
  SuGetAccount: {
    __typename?: "Account";
    _id: string;
    accountName: string;
    createdAt: string;
    accountType: AccountType;
    commission?: Maybe<number>;
    enabled: boolean;
    listingCount: number;
    business?: Maybe<{ __typename?: "Business"; identifier: string; name: string }>;
    users: Array<{
      __typename?: "User";
      _id: string;
      email: string;
      createDate: any;
      avatarURL?: Maybe<string>;
      aboutMe?: Maybe<string>;
      legalName: { __typename?: "LegalName"; firstName: string; lastName: string };
      contactNumber: Array<{
        __typename?: "ContactNumber";
        _id: string;
        countryCode: string;
        phoneNumber: string;
        verified: boolean;
      }>;
    }>;
  };
};

export type SuGetBillingQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type SuGetBillingQuery = {
  __typename?: "Query";
  SuGetAccount: {
    __typename?: "Account";
    billing?: Maybe<{
      __typename?: "AccountBilling";
      budgetMonthlyCents: number;
      defaultCpc: number;
      categoryCpc: Array<{ __typename?: "CategoryCpc"; cpc: number; categoryId: string; originalPath: string }>;
    }>;
  };
};

export type SuListAccountsQueryVariables = Exact<{
  search_term?: Maybe<Scalars["String"]>;
  filter: ESuListAccountFilterEnum;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type SuListAccountsQuery = {
  __typename?: "Query";
  SuListAccounts: {
    __typename?: "SuListAccountsResponse";
    totalItems: number;
    page: Array<{
      __typename?: "Account";
      _id: string;
      accountName: string;
      createdAt: string;
      accountType: AccountType;
      commission?: Maybe<number>;
      enabled: boolean;
      listingCount: number;
      business?: Maybe<{ __typename?: "Business"; name: string; identifier: string }>;
      users: Array<{
        __typename?: "User";
        email: string;
        superUser: boolean;
        createDate: any;
        avatarURL?: Maybe<string>;
      }>;
    }>;
  };
};

export const BpMoveListingCategoryDocument = gql`
  mutation BpMoveListingCategory($categoryId: String!, $listingId: String!) {
    BpMoveListingCategory(categoryId: $categoryId, listingId: $listingId) {
      listingId
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BpMoveListingCategoryGQL extends Apollo.Mutation<
  BpMoveListingCategoryMutation,
  BpMoveListingCategoryMutationVariables
> {
  document = BpMoveListingCategoryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BpMoveListingLocationDocument = gql`
  mutation BpMoveListingLocation($location: ListingLocationInput!, $listingId: String!) {
    BpMoveListingLocation(location: $location, listingId: $listingId) {
      listingId
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BpMoveListingLocationGQL extends Apollo.Mutation<
  BpMoveListingLocationMutation,
  BpMoveListingLocationMutationVariables
> {
  document = BpMoveListingLocationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateListingSingleDocument = gql`
  mutation CreateListingSingle(
    $accountId: String
    $title: String!
    $brand: String
    $description: String!
    $price: PricingInput!
    $bondPrice: Float!
    $images: [ImageInput!]!
    $externalURL: String
    $categoryId: String!
    $ownerRef: String
    $attributes: [AttributeValueInput!]!
    $location: ListingLocationInput
    $keywords: [String!]!
    $condition: Condition
    $rules: [String!]
  ) {
    CreateListing(
      accountId: $accountId
      title: $title
      brand: $brand
      description: $description
      keywords: $keywords
      pricing: $price
      attributes: $attributes
      location: $location
      bondPrice: $bondPrice
      condition: $condition
      rules: $rules
      images: $images
      externalURL: $externalURL
      categoryId: $categoryId
      ownerRef: $ownerRef
    ) {
      ... on CreateListingSuccess {
        listing {
          listingId
        }
      }
      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateListingSingleGQL extends Apollo.Mutation<
  CreateListingSingleMutation,
  CreateListingSingleMutationVariables
> {
  document = CreateListingSingleDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LoginWithPasswordDocument = gql`
  mutation LoginWithPassword($email: String!, $password: String!) {
    LoginWithPassword(email: $email, password: $password, staySignedIn: true) {
      ... on LoginSuccess {
        access_token
        user {
          _id
          accountId
          legalName {
            firstName
            lastName
          }
          setup {
            contactNumberVerified
          }
          contactNumber {
            _id
          }
          account {
            accountType
            accountName
            business {
              name
              identifier
            }
          }
          superUser
          email
          avatarURL
          setup {
            contactNumberVerified
          }
          contactNumber {
            _id
            countryCode
            phoneNumber
            verified
          }
        }
      }
      ... on LoginFailure {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class LoginWithPasswordGQL extends Apollo.Mutation<
  LoginWithPasswordMutation,
  LoginWithPasswordMutationVariables
> {
  document = LoginWithPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RefreshJwtDocument = gql`
  mutation refreshJWT {
    RefreshJWT {
      ... on LoginSuccess {
        access_token
        user {
          _id
          accountId
          legalName {
            firstName
            lastName
          }
          setup {
            contactNumberVerified
          }
          contactNumber {
            _id
          }
          account {
            accountName
            accountType
            business {
              name
              identifier
            }
          }
          superUser
          email
          avatarURL
          setup {
            contactNumberVerified
          }
          contactNumber {
            _id
            countryCode
            phoneNumber
            verified
          }
        }
      }
      ... on LoginFailure {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class RefreshJwtGQL extends Apollo.Mutation<RefreshJwtMutation, RefreshJwtMutationVariables> {
  document = RefreshJwtDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ResendVerificationCodeDocument = gql`
  mutation ResendVerificationCode($contactNumberId: String!) {
    ResendVerificationCode(contactNumberId: $contactNumberId) {
      ... on GenericSuccessType {
        success
      }
      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ResendVerificationCodeGQL extends Apollo.Mutation<
  ResendVerificationCodeMutation,
  ResendVerificationCodeMutationVariables
> {
  document = ResendVerificationCodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ReturnBookingDocument = gql`
  mutation ReturnBooking($bookingId: String!) {
    OwnerReturnBooking(bookingId: $bookingId) {
      ... on Booking {
        _id
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ReturnBookingGQL extends Apollo.Mutation<ReturnBookingMutation, ReturnBookingMutationVariables> {
  document = ReturnBookingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuUpdateBillingDocument = gql`
  mutation SuUpdateBilling(
    $categoryCpc: [CategoryCpcInput!]!
    $defaultCpc: Float!
    $budgetMonthlyCents: Float!
    $accountId: String!
  ) {
    SuUpdateBilling(
      categoryCpc: $categoryCpc
      defaultCpc: $defaultCpc
      budgetMonthlyCents: $budgetMonthlyCents
      accountId: $accountId
    )
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuUpdateBillingGQL extends Apollo.Mutation<SuUpdateBillingMutation, SuUpdateBillingMutationVariables> {
  document = SuUpdateBillingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuVerifyPhoneNumberDocument = gql`
  mutation SuVerifyPhoneNumber($verified: Boolean!, $contactNumberId: String!, $userId: String!) {
    SuVerifyPhoneNumber(verified: $verified, contactNumberId: $contactNumberId, userId: $userId)
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuVerifyPhoneNumberGQL extends Apollo.Mutation<
  SuVerifyPhoneNumberMutation,
  SuVerifyPhoneNumberMutationVariables
> {
  document = SuVerifyPhoneNumberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateListingDocument = gql`
  mutation UpdateListing(
    $listingId: String!
    $accountId: String!
    $title: String!
    $brand: String
    $description: String!
    $price: PricingInput!
    $bondPrice: Float!
    $externalURL: String
    $categoryId: String!
    $ownerRef: String
    $attributes: [AttributeValueInput!]!
    $location: ListingLocationInput
    $keywords: [String!]!
    $newImages: [ImageInput!]!
    $editImages: [EditListingImageInput!]!
    $condition: Condition
    $rules: [String!]
  ) {
    UpdateListing(
      listingId: $listingId
      accountId: $accountId
      title: $title
      brand: $brand
      description: $description
      keywords: $keywords
      pricing: $price
      attributes: $attributes
      location: $location
      bondPrice: $bondPrice
      condition: $condition
      rules: $rules
      newImages: $newImages
      editImages: $editImages
      externalURL: $externalURL
      categoryId: $categoryId
      batch: true
      ownerRef: $ownerRef
    ) {
      ... on EditListingSuccess {
        listing {
          listingId
        }
      }
      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateListingGQL extends Apollo.Mutation<UpdateListingMutation, UpdateListingMutationVariables> {
  document = UpdateListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateListingDocument = gql`
  mutation createListing(
    $accountId: String
    $title: String!
    $brand: String
    $description: String!
    $price: Float!
    $bondPrice: Float!
    $imageURL: String!
    $externalURL: String
    $categoryId: String!
    $ownerRef: String!
    $attributes: [AttributeValueInput!]!
    $location: ListingLocationInput
    $keywords: [String!]!
  ) {
    CreateListing(
      accountId: $accountId
      title: $title
      brand: $brand
      description: $description
      keywords: $keywords
      pricing: {
        monday: $price
        tuesday: $price
        wednesday: $price
        thursday: $price
        friday: $price
        saturday: $price
        sunday: $price
      }
      attributes: $attributes
      location: $location
      bondPrice: $bondPrice
      condition: GOOD
      rules: []
      images: { externalImageURL: $imageURL, sortOrder: 1 }
      externalURL: $externalURL
      categoryId: $categoryId
      batch: true
      ownerRef: $ownerRef
    ) {
      ... on CreateListingSuccess {
        listing {
          listingId
        }
      }
      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CreateListingGQL extends Apollo.Mutation<CreateListingMutation, CreateListingMutationVariables> {
  document = CreateListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DisableListingDocument = gql`
  mutation disableListing($listingId: String!) {
    BpDisableListing(listingId: $listingId) {
      listingId
      enabled
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class DisableListingGQL extends Apollo.Mutation<DisableListingMutation, DisableListingMutationVariables> {
  document = DisableListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EnableListingDocument = gql`
  mutation enableListing($listingId: String!) {
    BpEnableListing(listingId: $listingId) {
      listingId
      enabled
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class EnableListingGQL extends Apollo.Mutation<EnableListingMutation, EnableListingMutationVariables> {
  document = EnableListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($email: String!) {
    RequestPasswordReset(email: $email) {
      ... on GenericSuccessType {
        success
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
  document = ForgotPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RegisterDocument = gql`
  mutation Register(
    $mobileNumber: ContactNumberInput!
    $lastName: String!
    $firstName: String!
    $password: String!
    $email: String!
    $companyName: String!
    $abn: String!
  ) {
    Register(
      mobileNumber: $mobileNumber
      lastName: $lastName
      firstName: $firstName
      password: $password
      email: $email
      companyName: $companyName
      abn: $abn
    ) {
      ... on RegisterSuccess {
        user {
          _id
        }
      }
      ... on RegisterInvalidInputError {
        emailError
        code
        message
        passwordError
        firstNameError
        lastNameError
        mobileNumberError
        companyNameError
        abnError
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class RegisterGQL extends Apollo.Mutation<RegisterMutation, RegisterMutationVariables> {
  document = RegisterDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ResetPasswordDocument = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    ResetPassword(token: $token, newPassword: $newPassword) {
      ... on GenericSuccessType {
        success
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
  document = ResetPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuApproveB2cAccountDocument = gql`
  mutation SuApproveB2cAccount($id: String!) {
    SuApproveB2cAccount(_id: $id) {
      _id
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuApproveB2cAccountGQL extends Apollo.Mutation<
  SuApproveB2cAccountMutation,
  SuApproveB2cAccountMutationVariables
> {
  document = SuApproveB2cAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuApproveListingDocument = gql`
  mutation SuApproveListing($listingId: String!) {
    SuApproveListing(listingId: $listingId)
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuApproveListingGQL extends Apollo.Mutation<SuApproveListingMutation, SuApproveListingMutationVariables> {
  document = SuApproveListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuUnapproveB2cAccountDocument = gql`
  mutation SuUnapproveB2cAccount($id: String!) {
    SuUnapproveB2cAccount(_id: $id) {
      _id
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuUnapproveB2cAccountGQL extends Apollo.Mutation<
  SuUnapproveB2cAccountMutation,
  SuUnapproveB2cAccountMutationVariables
> {
  document = SuUnapproveB2cAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuUnApproveListingDocument = gql`
  mutation SuUnApproveListing($listingId: String!) {
    SuUnApproveListing(listingId: $listingId)
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuUnApproveListingGQL extends Apollo.Mutation<
  SuUnApproveListingMutation,
  SuUnApproveListingMutationVariables
> {
  document = SuUnApproveListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateMyAccountDetailsDocument = gql`
  mutation UpdateMyAccountDetails($abn: String, $businessName: String, $accountName: String) {
    UpdateMyAccountDetails(abn: $abn, businessName: $businessName, accountName: $accountName) {
      accountName
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateMyAccountDetailsGQL extends Apollo.Mutation<
  UpdateMyAccountDetailsMutation,
  UpdateMyAccountDetailsMutationVariables
> {
  document = UpdateMyAccountDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserAvatarDocument = gql`
  mutation UpdateUserAvatar($token: String!) {
    UpdateAvatar(imageToken: $token) {
      ... on UpdateAvatarSuccess {
        avatarURL
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class UpdateUserAvatarGQL extends Apollo.Mutation<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables> {
  document = UpdateUserAvatarDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const VerifyPhoneNumberDocument = gql`
  mutation VerifyPhoneNumber($verificationCode: String!, $contactNumberId: String!) {
    VerifyPhoneNumber(verificationCode: $verificationCode, contactNumberId: $contactNumberId) {
      ... on VerifyPhoneNumberSuccess {
        success
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class VerifyPhoneNumberGQL extends Apollo.Mutation<
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables
> {
  document = VerifyPhoneNumberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AvailableCategories2Document = gql`
  query AvailableCategories2($depth: Int!) {
    AvailableCategories2(depth: $depth) {
      categoryId
      categoryName
      urlSlug
      originalPath
      path
      pathMap
      depth
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AvailableCategories2GQL extends Apollo.Query<
  AvailableCategories2Query,
  AvailableCategories2QueryVariables
> {
  document = AvailableCategories2Document;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BpCategoriesSummaryDocument = gql`
  query BpCategoriesSummary($accountId: String, $allAccounts: Boolean) {
    BpCategoriesSummary(accountId: $accountId, allAccounts: $allAccounts) {
      categoryTallies {
        categoryId
        disabledCount
        enabledCount
        category {
          categoryName
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BpCategoriesSummaryGQL extends Apollo.Query<BpCategoriesSummaryQuery, BpCategoriesSummaryQueryVariables> {
  document = BpCategoriesSummaryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCategoryDocument = gql`
  query GetCategory($categoryId: String!) {
    Category(categoryId: $categoryId) {
      ... on Category {
        category {
          categoryId
          categoryName
          pathMap
          originalPath
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GetCategoryGQL extends Apollo.Query<GetCategoryQuery, GetCategoryQueryVariables> {
  document = GetCategoryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CategoryAttributesDocument = gql`
  query CategoryAttributes($categoryId: String!) {
    CategoryAttributes(categoryId: $categoryId) {
      attributeId
      attributeType
      selectOptions
      attributeName
      categoryIds
      required
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class CategoryAttributesGQL extends Apollo.Query<CategoryAttributesQuery, CategoryAttributesQueryVariables> {
  document = CategoryAttributesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EditExternalListingGetListingDocument = gql`
  query EditExternalListingGetListing($listingId: String!) {
    Listing(listingId: $listingId) {
      ... on ExternalListing {
        accountId
        listingId
        categoryId
        ownerUserId
        ownerRef
        title
        description
        keywords
        pricing {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        images {
          _id
          imageURL
          thumbnailURL
          heroURL
          sortOrder
        }
        location {
          postcode
          state
          locality
          latitude
          longitude
          national
        }
        bondPrice
        externalURL
        attributes {
          attributeId
          attributeType
          attributeName
          valueInt
          valueFloat
          valueString
          valueStringArray
          valueBoolean
        }
        brand
      }
      ... on InternalListing {
        listingId
        categoryId
        accountId
        ownerUserId
        ownerRef
        title
        description
        keywords
        pricing {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        images {
          _id
          imageURL
          thumbnailURL
          heroURL
          sortOrder
        }
        location {
          postcode
          state
          locality
          latitude
          longitude
          national
        }
        bondPrice
        attributes {
          attributeId
          attributeType
          attributeName
          valueInt
          valueFloat
          valueString
          valueStringArray
          valueBoolean
        }
        brand
        rules
        condition
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class EditExternalListingGetListingGQL extends Apollo.Query<
  EditExternalListingGetListingQuery,
  EditExternalListingGetListingQueryVariables
> {
  document = EditExternalListingGetListingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BpExportCsvListingsDocument = gql`
  query BpExportCsvListings(
    $enabled: Boolean
    $categoryId: String
    $search_term: String
    $accountId: String
    $approved: Boolean
    $all_accounts: Boolean
    $listingType: ListingType
    $location: ListingLocationInput
    $live: Boolean
    $notLiveReason: ENotLiveReason
  ) {
    BpExportCsvListings(
      enabled: $enabled
      categoryId: $categoryId
      search_term: $search_term
      accountId: $accountId
      approved: $approved
      all_accounts: $all_accounts
      listingType: $listingType
      location: $location
      live: $live
      notLiveReason: $notLiveReason
    ) {
      csvFileStr
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BpExportCsvListingsGQL extends Apollo.Query<BpExportCsvListingsQuery, BpExportCsvListingsQueryVariables> {
  document = BpExportCsvListingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FetchBusinessListingReportsDocument = gql`
  query fetchBusinessListingReports($startDate: DateTime!, $endDate: DateTime!, $accountId: String) {
    FetchBusinessListingReports(startDate: $startDate, endDate: $endDate, accountId: $accountId) {
      clicks
      impressions
      listing {
        title
      }
      listingId
      dateStr
      __typename
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class FetchBusinessListingReportsGQL extends Apollo.Query<
  FetchBusinessListingReportsQuery,
  FetchBusinessListingReportsQueryVariables
> {
  document = FetchBusinessListingReportsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAvailableCategoriesDocument = gql`
  query GetAvailableCategories {
    AvailableCategories {
      ... on Category {
        category {
          categoryId
          categoryName
          urlSlug
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GetAvailableCategoriesGQL extends Apollo.Query<
  GetAvailableCategoriesQuery,
  GetAvailableCategoriesQueryVariables
> {
  document = GetAvailableCategoriesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetBookingsDocument = gql`
  query GetBookings($statuses: [BookingStatus!]) {
    BpGetBookings(statuses: $statuses) {
      ... on Booking {
        _id
        listingId
        dateStart
        dateEnd
        dateCreated
        status
        totalOfferPrice
        refundableBondPrice
        listing {
          listingId
          title
          images {
            thumbnailURL
          }
        }
        seekerUser {
          _id
          legalName {
            firstName
            lastName
          }
          email
          contactNumber {
            phoneNumber
            countryCode
          }
          avatarURL
        }
        ownerAccount {
          _id
          accountName
          avatarUrl
          primaryUser {
            email
            contactNumber {
              phoneNumber
              countryCode
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GetBookingsGQL extends Apollo.Query<GetBookingsQuery, GetBookingsQueryVariables> {
  document = GetBookingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AvailableCategoriesDocument = gql`
  query AvailableCategories {
    AvailableCategories {
      __typename
      ... on Category {
        category {
          categoryId
          categoryName
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class AvailableCategoriesGQL extends Apollo.Query<AvailableCategoriesQuery, AvailableCategoriesQueryVariables> {
  document = AvailableCategoriesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetMyListingsDocument = gql`
  query GetMyListings {
    Listings(filter: { loggedInUser: true }) {
      ... on Listing {
        title
        description
        category {
          categoryName
        }
        pricing {
          monday
          tuesday
          wednesday
          thursday
          friday
        }
        images {
          sortOrder
          thumbnailURL
          heroURL
        }
        ownerRef
        enabled
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class GetMyListingsGQL extends Apollo.Query<GetMyListingsQuery, GetMyListingsQueryVariables> {
  document = GetMyListingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BpListListingsDocument = gql`
  query BpListListings(
    $limit: Int!
    $offset: Int!
    $search_term: String
    $categoryId: String
    $enabled: Boolean
    $accountId: String
    $approved: Boolean
    $all_accounts: Boolean
    $listingType: ListingType
    $sort_direction: ListingSortDirection
    $sort_order: ListingSortOrder
    $location: ListingLocationInput
    $live: Boolean
    $notLiveReason: ENotLiveReason
  ) {
    BpListListings(
      limit: $limit
      offset: $offset
      search_term: $search_term
      categoryId: $categoryId
      enabled: $enabled
      accountId: $accountId
      approved: $approved
      all_accounts: $all_accounts
      listingType: $listingType
      sort_direction: $sort_direction
      sort_order: $sort_order
      location: $location
      live: $live
      notLiveReason: $notLiveReason
    ) {
      ... on BpListListingsResponse {
        totalItems
        page {
          ... on InternalListing {
            _id
            listingId
            accountId
            title
            live
            notLiveReason
            description
            ownerRef
            listingType
            enabled
            approved
            images {
              thumbnailURL
            }
            category {
              originalPath
            }
            location {
              national
              postcode
              state
              locality
            }
          }
          ... on ExternalListing {
            _id
            listingId
            accountId
            title
            live
            notLiveReason
            description
            ownerRef
            listingType
            enabled
            approved
            externalURL
            images {
              thumbnailURL
            }
            category {
              originalPath
            }
            location {
              national
              postcode
              state
              locality
            }
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class BpListListingsGQL extends Apollo.Query<BpListListingsQuery, BpListListingsQueryVariables> {
  document = BpListListingsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LocalityAutocompleteDocument = gql`
  query LocalityAutocomplete($term: String!) {
    LocalityAutocomplete(localityPartial: $term) {
      locality
      postcode
      state
      latitude
      longitude
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class LocalityAutocompleteGQL extends Apollo.Query<
  LocalityAutocompleteQuery,
  LocalityAutocompleteQueryVariables
> {
  document = LocalityAutocompleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LocationCsvAutocompleteDocument = gql`
  query LocationCsvAutocomplete($postcode: String!, $suburb: String) {
    LocalityCsvAutocomplete(postcode: $postcode, suburb: $suburb) {
      ... on ListingLocation {
        postcode
        state
        locality
        latitude
        longitude
        national
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class LocationCsvAutocompleteGQL extends Apollo.Query<
  LocationCsvAutocompleteQuery,
  LocationCsvAutocompleteQueryVariables
> {
  document = LocationCsvAutocompleteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OwnerRefExistsDocument = gql`
  query OwnerRefExists($ownerRef: String!, $accountId: String!, $ignoreListingId: String) {
    BpOwnerRefExists(ownerRef: $ownerRef, accountId: $accountId, ignoreListingId: $ignoreListingId) {
      exists
      listingId
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class OwnerRefExistsGQL extends Apollo.Query<OwnerRefExistsQuery, OwnerRefExistsQueryVariables> {
  document = OwnerRefExistsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RequestImageTokenDocument = gql`
  query RequestImageToken($qty: Int!) {
    RequestImageToken(qty: $qty) {
      ... on ImageToken {
        token
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class RequestImageTokenGQL extends Apollo.Query<RequestImageTokenQuery, RequestImageTokenQueryVariables> {
  document = RequestImageTokenDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuGetAccountDocument = gql`
  query SuGetAccount($id: String!) {
    SuGetAccount(_id: $id) {
      ... on Account {
        _id
        accountName
        createdAt
        accountType
        commission
        business {
          identifier
          name
        }
        enabled
        users {
          _id
          email
          legalName {
            firstName
            lastName
          }
          createDate
          avatarURL
          aboutMe
          contactNumber {
            _id
            countryCode
            phoneNumber
            verified
          }
        }
      }
      listingCount
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuGetAccountGQL extends Apollo.Query<SuGetAccountQuery, SuGetAccountQueryVariables> {
  document = SuGetAccountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuGetBillingDocument = gql`
  query SuGetBilling($id: String!) {
    SuGetAccount(_id: $id) {
      billing {
        budgetMonthlyCents
        defaultCpc
        categoryCpc {
          cpc
          categoryId
          originalPath
        }
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuGetBillingGQL extends Apollo.Query<SuGetBillingQuery, SuGetBillingQueryVariables> {
  document = SuGetBillingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SuListAccountsDocument = gql`
  query SuListAccounts($search_term: String, $filter: ESuListAccountFilterEnum!, $offset: Int!, $limit: Int!) {
    SuListAccounts(search_term: $search_term, filter: $filter, offset: $offset, limit: $limit) {
      page {
        _id
        business {
          name
          identifier
        }
        users {
          email
          superUser
          createDate
          avatarURL
        }
        accountName
        createdAt
        accountType
        commission
        enabled
        listingCount
      }
      totalItems
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class SuListAccountsGQL extends Apollo.Query<SuListAccountsQuery, SuListAccountsQueryVariables> {
  document = SuListAccountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
